import React from "react"
import { graphql } from "gatsby"
import Team from "../components/team"
import Layout from "../components/layout"

const Equipo = ({ location }) => {
    const pageTitle = (
        <span>
            Te presentamos al equipo
            <br />
            <em>Open Green Road</em>
        </span>
    )

    return (
        <Layout location={location} title={pageTitle}>
            <Team />
        </Layout>
    )
}

export default Equipo

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
