import React from "react"
import styles from "./team.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Team = () => {
    const data = useStaticQuery(graphql`
        query TeamQuery {
            allMarkdownRemark(
                filter: { fields: { sourceInstanceName: { eq: "team" } } }
                sort: { order: ASC, fields: frontmatter___order }
            ) {
                edges {
                    node {
                        html
                        frontmatter {
                            name
                            position
                            order
                            photo {
                                childImageSharp {
                                    fixed(width: 190, quality: 100) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.container}>
            {data.allMarkdownRemark.edges.map((team, index) => {
                return (
                    <div key={index} className={styles.profile}>
                        <Image
                            fixed={team.node.frontmatter.photo.childImageSharp.fixed}
                            style={{
                                maxWidth: `100%`
                            }}
                            imgStyle={{
                                width: `100%`,
                                height: `auto`
                            }}
                        />
                        <p className={styles.nombre}>{team.node.frontmatter.name}</p>
                        <p className={styles.posicion}>{team.node.frontmatter.position}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Team
